import {
  BaseConfiguration,
} from '../../../../../vendor/base-component-class/config.class';
import RecapComponent from './recap';

const salesFrameConfig = {
  name: 'sells',
  title: 'Lste des factures',
  typeFrame: 'list',
  fields: [
    {
      name: 'sellingNumber',
      title: 'N° de facture',
    },
    {
      name: 'customer',
      title: 'Client',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'clients',
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
    },
    {
      name: 'date',
      title: 'Date vente',
      type: 'datetime',
    },
    {
      name: 'netToPaid',
      title: 'Coût total',
      type: 'money',
    },
    {
      name: 'totalSellCost',
      title: 'VENTE',
      type: 'money',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'isPaid',
      title: 'Etat',
      options: {
        0: {badgeColor: '#DC143C', text: 'Impayée', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Payée'},
      },
      type: 'checkBox',
    },
  ],
  excludeToHeaders: [
    'articleSoldList',
    'amountPaid',
    'discount',
    'paymentType',
    'tvaValue',
    'currency',
    'totalSellCost',
    'customerId',
    'waiting',
  ],
  excludeToForms: [
    'sellingNumber',
    'date',
    'totalcostTtc',
    'cashRegisterNumber',
    'state',
    'customer',
    'netToPaid',
  ],
  filterOptions: [
    {
      name: 'date',
      type: 'interval-time',
    },
    {
      name: 'sellingNumber',
      title: 'N° de vente',
    },
    {
      name: 'customerId',
      type: 'select',
      title: 'Client',
      optionsTarget: 'clients',
    },
    {
      name: 'status',
      type: 'select',
      title: 'Statut',
      options: [{label: 'Impayé', value: 0}, {label: 'Payé', value: 1}],
    },
  ],
  dialogsConfig: {
    singleDialog: {
      size: 'md',
    },
    formDialog: {
      size: 'xl',
    },
  },

  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'primary',
      icon: 'eye',
    },
  ],
  componentBeforeRenderList: RecapComponent,
  hideBar: true,
  frameActions: {
    tableActions: [
      {
        frameType: 'list',
        actions: [],
      },
    ],
  },
};

export default BaseConfiguration (salesFrameConfig);
