import BaseBackComponent from './../../../base-back-component';
import SupplierInvoiceScreen from './screen';
import FsinvoiceService from './../../../../../external-communication/services/fsinvoice.service';

export default class SupplierInvoices extends BaseBackComponent {
  name = 'supplier-invoices';

  options = [
    {
      title: 'suppliers',
      keys: ['firstName'],
    }
  ];
  
  state = {
    ...this.state,
    screen: SupplierInvoiceScreen,
    searchOptions: {
      ...this.state.searchOptions,
      status: null,
      clientId: null,
    },
  };

  async componentDidMount () {
    this.loadOptions();
  }

  async componentDidUpdate(prevProps,prevState){
    if(this.props.params !== prevProps.params){
        await this.setState ({
            service: new FsinvoiceService (),
            searchOptions: {
              ...this.state.searchOptions,
              customerId: this.props.params?.clientId,
            },
        });
          
        this.initDataLoading ();
    }
  }
}
