import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const suppliersFrameConfig = {
  name: 'suppliers',
  title: 'Fournisseurs',
  typeFrame: 'list',
  fields: [
    {
      name: 'articleFamilyId',
      title: "Famille d'article",
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlefamillies',
      selectDataValue: {
        name: 'articleFamily',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      col: '6',
    },
    {
      name: 'articleFamily',
      title: "Famille d'article",
    },
    {
      name: 'addresse',
      title: 'Adresse',
      col: '6',
    },
    {
      name: 'email',
      title: 'Email',
      col: '6',
      type: 'email',
    },
    {
      name: 'firstName',
      title: 'raison social',
      col: '6',
    },
    /* {
      name: 'accountNumber',
      title: 'N° de compte général',
      //type: 'objectFetcher',
      //isObject: true,
      //fieldsSelected: ['accountNumber'],
    },*/
    {
      name: 'accountNumber',
      title: 'N° de compte général',
      col: '4',
    },
    {
      name: 'bankAccountNumber',
      title: 'NCC',
      col: '4',
    },

    {
      name: 'auxiliaryAccountNumber',
      title: 'Compte auxiliaire',
      col: '4',
    },
    /*{
      name: 'account',
      title: 'Compte auxiliaire',
      type: 'objectFetcher',
      isObject: true,
     // fieldsSelected: ['auxiliaryAccount'],
      col: '4',
    },*/
    {
      name: 'tel',
      title: 'Téléphone',
      col: '6',
      type: 'phone',
    },
    {
      name: 'creationDate',
      title: "Date d'ajout",
      type: 'date',
      col: '6',
    },
    {
      name: 'balance',
      title: 'Dû',
      type: 'money',
      col: '6',
    },
    {
      name: 'ceilingBalance',
      title: 'Solde plafond',
      type: 'money',
      col: '6',
    },
  ],
  filterOptions: [
    {
      name: 'firstName',
      title: 'nom',
    },
    {
      name: 'lastName',
      title: 'prénom(s)',
    },
  ],
  excludeToHeaders: [
    'email',
    'addresse',
    'articleFamilyId',
    'auxiliaryAccount',
    'generalAccountNumber',
  ],
  excludeToForms: ['creationDate', 'balance', 'articleFamily'],
  dialogsConfig: {
    formDialog: {
      size: 'sm',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (suppliersFrameConfig);
