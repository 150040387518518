import Service from '../../vendor/bases/service';

export default class SuppliersService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'suppliers',
    });
  }
  async suppliersWithBalance () {
    return await this.treatQuery (this.query.suppliersWithBalance ());
  }
}
