import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import moment from 'moment';
import {useState} from 'react';
import  DateRangePickerValue  from '../../../../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date';

const DailySellsRender = props => {
    let renderConfigs = props.data;

    const changeDateValue = (e, value = null) => {
      renderConfigs.screenActions.handleSearch (e, value, 'date');
    };
  
    const data = renderConfigs?.data || [];
    
    let totalLineByCustomerType = [];  
    let totalLineByWay = [];
  
    let totalColumnGlovo = 0;
    let totalColumnDivers = 0;
    let totalColumnB2B = 0;
    let totalColumnInternal = 0;
  
  
    let totalColumnCash = 0;
    let totalColumnBanque = 0;
    let totalColumnCredit = 0;
  
    let totalWayPaymentColumn = 0;
    let totalClientTypeColumn = 0;
  
  
    if(data && data.length > 0){
        data.forEach(
          line => {
            let totalCustomerTypeLine = 0;
            let totalWayLine = 0;
  
            line.sellByPayementModeList.forEach(
              sell => {
                totalWayLine += sell.value || 0    
                
              }
            )
            
            line.sellByCustomerList.forEach(
              sell => {
                totalCustomerTypeLine += sell.value || 0
              }
            )
  
            totalColumnGlovo += getFieldToTal(line.sellByCustomerList,"GLOVO");
            totalColumnDivers += getFieldToTal(line.sellByCustomerList,"DIVERS");
            totalColumnB2B += getFieldToTal(line.sellByCustomerList,"B TO B");
            totalColumnInternal += getFieldToTal(line.sellByCustomerList,"Client INTERNE");
  
            totalColumnCash += getFieldToTal(line.sellByPayementModeList,"CASH");
            totalColumnBanque += getFieldToTal(line.sellByPayementModeList,"BANQUE");
            totalColumnCredit += getFieldToTal(line.sellByPayementModeList,"CREDIT");
    
            totalLineByCustomerType.push(totalCustomerTypeLine);
            totalClientTypeColumn += totalCustomerTypeLine;
            totalWayPaymentColumn += totalWayLine;
            totalLineByWay.push(totalWayLine);
          }
        )
    }
  
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    return <div className="">
              <div className="title text-white p-2">
                VENTE JOURNALIERE
              </div>
              <div className="col-12 d-flex justify-content-start my-3">
                  <DateRangePickerValue
                    size="small"
                    changeSearchValue={changeDateValue}
                    data={{
                      name: 'date',
                    }}
                  />
              </div>
              
              <Box sx={{ width: '100%' }}>     
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 450 }}>
                    <Table stickyHeader size={'small'}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan="2">
                          </TableCell>
                          <TableCell align="center">
                            {totalColumnCash}
                          </TableCell>
                          <TableCell align="center">
                            {totalColumnCredit}
                          </TableCell>
                          <TableCell align="center">
                            {totalColumnBanque}
                          </TableCell>
                          <TableCell align="center">
                            {totalWayPaymentColumn}
                          </TableCell>
                          <TableCell align="center">
                            {totalColumnInternal}
                          </TableCell>
                          <TableCell align="center">
                          {totalColumnGlovo}
                          </TableCell>
                          <TableCell align="center">
                          {totalColumnB2B}
                          </TableCell>
                          <TableCell align="center">
                          {totalColumnDivers}
                          </TableCell>
                          <TableCell align="center">
                          {totalClientTypeColumn}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" colSpan="2">
                          </TableCell>
                          <TableCell align="center" colSpan="4">
                            Moyen de règlement
                          </TableCell>
                          <TableCell align="center" colSpan={4}>
                            Catégorie client
                          </TableCell>
                          <TableCell align="center">
                          </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align={"center"}>Date</TableCell>
                        <TableCell align={"center"}>Caisse</TableCell>
                          <TableCell
                              align={"center"}
                          >
                            CASH
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            CREDIT
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            BANQUE
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            TOTAL
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            CLIENTS INTERNE
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            GLOVO
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            B TO B
                          </TableCell>
                          <TableCell
                              align={"center"}
                              
                          >
                            CLIENTS DIVERS
                          </TableCell>
                          <TableCell
                              align={"center"}
                          >
                            TOTAL
                          </TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map(
                            (option,index) => <TableRow key={option.cashRegister}>
                                          <TableCell align="center">
                                            {moment(option?.date).format("DD-MM-YYYY HH:mm:ss")}
                                          </TableCell>
                                          <TableCell align="center">
                                            {option?.cashRegister?.toUpperCase()}
                                          </TableCell>
                                          <TableCell align="center">
                                            {option?.sellByPayementModeList?.find(pa => pa.libelle.toUpperCase() === "CASH".toUpperCase())?.value || 0}
                                          </TableCell>
                                          <TableCell align="center">
                                            {option?.sellByPayementModeList?.find(pa => pa.libelle.toUpperCase() === "CREDIT".toUpperCase())?.value || 0}
                                          </TableCell>
                                          <TableCell align="center">
                                            {option?.sellByPayementModeList?.find(pa => pa.libelle.toUpperCase() === "BANQUE".toUpperCase())?.value}
                                          </TableCell>
                                          <TableCell align="center">
                                            {totalLineByWay[index]}
                                          </TableCell>
                                          <TableCell align="center">
                                          {option?.sellByCustomerList?.find(pa => pa.libelle.toUpperCase() === "CLIENT INTERNE".toUpperCase())?.value || 0}
                                          </TableCell>
                                          <TableCell align="center">
                                            {option?.sellByCustomerList?.find(pa => pa.libelle.toUpperCase() === "GLOVO".toUpperCase())?.value || 0}
                                          </TableCell>
                                          <TableCell align="center">
                                          {option?.sellByCustomerList?.find(pa => pa.libelle.toUpperCase() === "B TO B".toUpperCase())?.value || 0}
                                          </TableCell>
                                          <TableCell align="center">
                                          {option?.sellByCustomerList?.find(pa => pa.libelle.toUpperCase() === "Divers".toUpperCase())?.value || 0}
                                          </TableCell>
                                          <TableCell align="center">
                                          {totalLineByCustomerType[index]}
                                          </TableCell>
                                        
                                      </TableRow>
  
                          )
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>  
              </Box>
     </div>;
  }
  
  
  function  getFieldToTal(list, fieldName){
      return list.find(item => item.libelle.toLowerCase() === fieldName.toLowerCase())?.value || 0;
  }

export default DailySellsRender;
