import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function RecapComponent (props) {
  let totalSold = 0;
  let totalDebt = 0;

  if(props?.items){
    props?.items?.forEach(
        item => {
            totalSold += item?.netToPaid;
            if(!item.isPaid){
                totalDebt += item?.netToPaid;
            }
        }
    )
  }

  return (
    <div className="row p-2 my-2 bg-light">
      <div className="col">
        <div className="card radius-10 border-start border-0 border-3 border-info">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div>
                <p className="mb-0 text-secondary">Total vente</p>
                <h5 className="my-1 text-info">
                  {Intl.NumberFormat ('fr-FR', {
                    style: 'currency',
                    currency: 'XOF',
                  }).format (totalSold)}{' '}
                </h5>
              </div>
              <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                <FontAwesomeIcon icon="wallet" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card radius-10 border-start border-0 border-3 border-info">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div>
                <p className="mb-0 text-secondary">Total dette</p>
                <h5 className="my-1 text-info">
                  {Intl.NumberFormat ('fr-FR', {
                    style: 'currency',
                    currency: 'XOF',
                  }).format (totalDebt)}{' '}
                </h5>
              </div>
              <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                <FontAwesomeIcon icon="wallet" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
