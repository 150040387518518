import DefaultQuery from '../../vendor/bases/default.query';

export default class SuppliersQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'suppliers',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list'), params);
  }

  async suppliersWithBalance () {
    return await this.getMethod (this.buildPath ('with-balance'));
  }
}
