import BaseBackComponent from '../base-back-component';
import SupplierdeliveriesService from './../../../external-communication/services/supplierdeliveries.service';

export default class SupplierpaymentsComponent extends BaseBackComponent {
  name = 'Supplierpayments';

  options = [
    {
      title: 'suppliers',
      keys: ['firstName'],
      method: 'suppliersWithBalance',
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      supplierId: null,
      data: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async otherHandleFieldTreatment (name, value) {
    if (name === 'supplierId') {
     
      const queryInvoices = await new SupplierdeliveriesService ().unpaidInvoice (
        value
      );
     
      let invoices = [];
  
      if(queryInvoices?.success){
        invoices = this.loadInvoices(queryInvoices?.data);
      }

      let newOptions  = this.state.options; 
      
      const indexOfArticleOptions = newOptions?.findIndex(item => item.title === "delivers");

      if(indexOfArticleOptions !== -1){
        newOptions.splice(indexOfArticleOptions,1);
      }

      newOptions.push({
        title : "delivers",
        options : invoices
      })

      await this.setState({
        options: newOptions,
        formPayload : {
          ...this.state.formPayload,
          amount : {
            value : 0 
          }
        }
      })

      console.log(name)
     
    }

     
    if(name === 'deliveryList'){
       
      
      let amount = 0;

      if(value && value?.length > 0){
        
        const sales =  this.state.options.find(option => option.title === "delivers").options;

        value?.forEach(element => {
            const sValue =  sales.find(sale => sale.value === element)?.originalObject
      console.log("val :", sValue)

            if(sValue){
             
              amount += (sValue?.totalTtc)
            }
        });
        
      }

      await this.setState({
        formPayload : {
          ...this.state.formPayload,
          amount : {
            value : amount 
          }
        }
      });
    }
    
  }

  


loadInvoices(data){
  return data.map (invoice => {
    let totalTtc = 0;
    
    invoice.rawMaterialDeliveredList.forEach(
      (raw) => {
        totalTtc += (raw.qtyDelivered * raw.unitPrice) 
      }
    );

    return {
      value: invoice.id,
      label: invoice.deliveryNumber,
      originalObject : {
        ...invoice,
        totalTtc : totalTtc
      }
    };
  });
}

otherSingleDataInit (data) {
  data = {
    ...data,
    supplierId : {value : data?.supplier?.value?.id},
    payementMode : {value : data?.payementMode?.value},
    deliveryList : {value : data?.deliveryList?.value}
  };
  return data;
}
}
