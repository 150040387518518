import ClientordersService from '../../../external-communication/services/clientorders.service';
import BaseBackComponent from '../base-back-component';

export default class ClientdeliversComponent extends BaseBackComponent {
  name = 'Clientdelivers';

  options = [
    {
      title: 'orders',
      source: 'clientorders',
      keys: ['orderingNumber'],
      method : "notDeliverCmd"
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      deliverNumber: null,
      date: null,
      state: null,
      customerOrderId: null,
      size: 10,
    },
  };

  async componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async otherHandleFieldTreatment(name,value){
    if(name === "orderId"){
        let newArticlesFormValue = null;
        if(value && value !== "") {
            const queryOrderArticles = await new ClientordersService().getOrderArticles(value);

            let totalCost = 0;
           
            if(queryOrderArticles?.success){
                
               newArticlesFormValue = this.setPayloadData(queryOrderArticles?.data);

               queryOrderArticles?.data?.forEach(
                item => {
                  totalCost += item?.cost * item?.qtyAvailable;
                }
              )
              
             // console.log("totalCost : ", totalCost)
             
               await this.setState({
                  formPayload : {
                    ...this.state.formPayload,
                    articlesTODeliver : {
                      value : newArticlesFormValue 
                    },
                    totalCost : {value : totalCost } 
                  }
               })

               await this.amountActualize({totalProductCost : totalCost, newArticles : newArticlesFormValue});
              
            }
        }else{
          newArticlesFormValue = null;
          await this.setState({
            formPayload : {
              ...this.state.formPayload,
              articlesTODeliver : {
                value : newArticlesFormValue 
              }
            }
          })
          //await this.amountActualize({totalProductCost : 0});
        }
       
    }

    if(name === "discount" || name === "tvaValue" || name === "amountPaid"){
     
      this.amountActualize({[name] : value});
    }

    if(name === "paymentType"){

      await this.setState(
        (state) => ({
          formPayload : {
            ...state.formPayload,
            amountPaid : {
              ...state.formPayload.amountPaid,
              disable : value?.toUpperCase() === "CREDIT"
            },
          }
      }));
    }
  }

  setPayloadData(articles){
    let data = [];
    articles.forEach(
      item => {
          data.push({
            articleName : {value : item?.articleName},
            article_id : {value : item?.id},
            qty : {value : item?.qtyAvailable},
            cost : {value : item?.qtyAvailable * item?.cost},
            unitPrice : {value : item?.cost}
          })
        }
    )

    return data;
  }
  
  async initProductsOptions(products,originalData){
    let newOptions = this.state.options;

    const productsOptionsIndex = newOptions.findIndex(
      option => option.title === "products"
    );

    if(productsOptionsIndex !== -1){
        newOptions.splice(productsOptionsIndex,1);
    }
    
    newOptions.push({title : "products",options : products});

    await this.setState({
      options : newOptions,
      originalOptionsData : {
        ...this.state.optionsData,
        products : originalData
      }
    });
  }

  loadOrderProducts(articles){
    return articles.filter(article => article.qtyAvailable !== 0).map (item => {
      return {
        value: item.id,
        label: item.articleName,
      };
    });
  }

  async handleSubForm (index, name, value, currentPayLoad = null) {
    let totalCost = 0;
    if (name === 'articleId') {
      if(value && value !== ""){
       
        let product = null;
        let qty = 0;
        
        product = this.state.originalOptionsData.products.find(prod => prod.id === value) ;
  
        if(product){
            qty = product.qtyAvailable;
        }
  
        currentPayLoad[index].qty = {value : qty};
        currentPayLoad[index].cost = {value : product.qtyAvailable * product?.cost}
      
      }
    }
    
    if(name === 'qty'){
     const newCost  = value * currentPayLoad[index]?.unitPrice?.value;
     currentPayLoad[index].cost = {value : newCost};
    }

    currentPayLoad.forEach(
      item => {
        totalCost += item?.cost?.value;
      }
    )

    await this.setState({
        formPayload : {
          ...this.state.formPayload,
          articlesTODeliver : {value : currentPayLoad},
          totalCost : {value : totalCost } 
        }
    });

   
    this.amountActualize({totalProductCost : totalCost});
  }

  otherSingleDataInit (data) {
    const articleList = data?.articleDelivers.value.map(
      article => {
        return {
          articleName : {value : article.article.name}, 
          article_id : {value : article.article.id},
          qty : {value : article.qtyDelivered},
          cost : {value : article.qtyDelivered * article.cost},
        }
      }
    ); 

    data = {
      ...data,
      orderId : {value : data?.order?.value?.id},
      articlesTODeliver : {value : articleList},
    }
    return data;
  }

  async initPayload () {

    await this.setState ({
      formPayload: {
        currency: {value: "0"},
        totalSellCost: {value: "0"},
        totalCost: {value: "0"},
       // tvaValue: {value: "0",maxValue : "100"},
       // discount: {value: "0"},
        totalProductsCost : {value : "0"},
        paymentType : {value : "CASH"},
        waiting:{value : "0"},
        //customerId : {value : clientDivers.value}
      },
    });
  }

  async resetPayload () {
    await this.setState ({
      singleData: {},
    });
    await this.initPayload ();
  }


  async amountActualize(params){


    let amountPaid = params?.amountPaid || this.state.formPayload.amountPaid?.value;
   
    //let tvaValue = params?.tvaValue || this.state.formPayload.tvaValue?.value;
    //let discount = params?.discount || this.state.formPayload.discount?.value;
    let totalProductsCost = params?.totalProductCost || this.getAllArticleCost();

    

   // let totalCost = parseFloat(totalProductsCost) + parseFloat(totalProductsCost) * parseFloat(tvaValue) / 100 - parseFloat(discount);
    
   let totalCost = parseFloat(totalProductsCost);
   
    let currency = amountPaid - totalCost;
    

    await this.setState({
      formPayload : {
        ...this.state.formPayload,
        currency : {value : currency > 0 ? currency : "0"},
        totalDeliverCost : {value : `${parseFloat(totalProductsCost)}`},
        articlesTODeliver : {
          value : params?.newArticles || this.state.formPayload?.articlesTODeliver?.value 
        },
      }
    });

  }

  getAllArticleCost(){
    let oldCost = 0;
    this.state.formPayload?.articlesTODeliver?.value.forEach(
      item => {
        oldCost += item?.cost?.value
      }
    )
    return oldCost;
  }


}
