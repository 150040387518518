import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const clientDeliversFrameConfig = {
  name: 'clientdelivers',
  title: 'Livraison(s) client',
  typeFrame: 'list',
  fields: [
    {
      name: 'deliverNumber',
      title: 'N°',
    },
    {
      name: 'date',
      title: 'Date livraison programmé',
      type: 'datetime',
      col: 6,
    },
    {
      name: 'deliveringDate',
      title: 'Date de livraison',
      type: 'datetime',
    },
    {
      col: 6,
      name: 'orderId',
      title: 'N° de commande',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'orders',
      fieldToTake: ['order'],
      type: 'objectFetcher',
      fieldsSelected: ['orderingNumber'],
      selectDataValue: {
        name: 'customer',
        options: {
          valueOptions: ['id'],
          labelOptions: ['orderingNumber'],
        },
      },
    },
    {
      name: 'order',
      title: 'Bon de commande',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'orders',
      selectDataValue: ['orderingNumber'],
      fieldToTake: ['order'],
      type: 'objectFetcher',
      fieldsSelected: ['orderingNumber'],
    },
    {
      name: 'articlesTODeliver',
      htmlElement: 'dynamicUnChangeForm',
      title: 'Article(s) livré(s)',
      fields: [
        /*{
          name: 'article_id',
          title: 'Article',
          htmlElement: 'select',
          dataServiceSource: 'products',
          fieldToTake: ['article'],
          type: 'objectFetcher',
          //  fieldsSelected: ['name'],
        },*/
        {
          name: 'articleName',
          title: 'Article',
          htmlElement: 'disableInput',
          editableDisable: true,
        },
        {
          name: 'qty',
          title: 'quantité',
          type: 'number',
          htmlElement: 'disableInput',
        },
        {
          name: 'cost',
          title: 'Coût',
          type: 'number',
          htmlElement: 'disableInput',
          editableDisable: true,
          dataValue: {
            value: 0,
          },
        },
      ],
    },
    {
      name: 'paymentType',
      title: 'Moyen de paiement',
      htmlElement: 'select',
      col: '6',
      options: [
        {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
        {value: 'BANQUE', label: 'BANQUE'},
        {value: 'CASH', label: 'CASH'},
        {value: 'CREDIT', label: 'CREDIT'},
      ],
    },
    {
      name: 'amountPaid',
      title: 'Montant reçu',
      type: 'number',
      col: '6',
    },
    {
      name: 'currency',
      title: 'MONNAIE',
      type: 'money',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'totalDeliverCost',
      title: 'MONTANT TOTAL',
      type: 'money',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },

    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {
          badgeColor: 'orange',
          text: 'En attente',
          textColor: 'white',
        },
        1: {badgeColor: '#5F9EA0', text: 'Retirée'},
        2: {badgeColor: '#DC143C', text: 'Annulée'},
      },
      type: 'state-multiple',
    },
  ],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Retirée',
          value: 1,
        },
        {
          label: 'Annulée',
          value: 3,
        },
      ],
    },
    {
      name: 'customerOrderId',
      title: 'N° de commande',
      type: 'select',
      optionsTarget: 'orders',
    },
    {
      name: 'deliverNumber',
      title: 'N° de livraison',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  excludeToHeaders: [
    'orderId',
    'articlesTODeliver',
    'totalDeliverCost',
    'amountPaid',
    'currency',
  ],
  excludeToForms: ['order', 'state', 'deliverNumber', 'deliveringDate'],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'quittance',
      title: 'Reçu',
      color: 'info',
      icon: 'paperclip',
      elementTest: 'state',
      value: 1,
      operator: 'equal',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
    deliverDialog: {
      size: 'sm',
    },
    quittanceDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (clientDeliversFrameConfig);
